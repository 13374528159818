import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ChatRoom from "./pages/ChatRoom";
import NoMatch from "./pages/NoMatch";

const App = () => {
  return (
    <Fragment>
      <Router>
        <Switch>
          <Route exact path="/:room">
            <ChatRoom />
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Router>
    </Fragment>
  );
};

export default App;
