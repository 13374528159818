import React, { useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

const Header = ({ joined, user, leaveRoom }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <div>
      <Navbar color="dark" dark expand="md">
        <NavbarBrand href="$">
          <img
            alt=""
            style={{ height: "40px" }}
            src="https://topaz-gopher-8158.twil.io/assets/perficient.jpg"
          />
          <span className="font-weight-bold ml-3">Programmable Video POC</span>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {user && joined && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Welcome {user}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={leaveRoom}>Leave Chat</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
