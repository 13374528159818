import React from "react";
import { Card, Form, FormGroup, Input, Button, Alert } from "reactstrap";

const UserForm = ({ handleSubmit, handleChange, user, pin, error }) => {
  return (
    <Card className="p-2 mt-2" style={{ backgroundColor: "#F8F8F8" }}>
      <Form className="" onSubmit={handleSubmit}>
        <FormGroup>
          <Input
            className="float-left mr-2"
            style={{ width: "130px" }}
            placeholder="Name"
            name="user"
            value={user}
            onChange={handleChange}
          />
          <Input
            className="float-left mr-2"
            style={{ width: "130px" }}
            placeholder="Pin"
            name="pin"
            value={pin}
            onChange={handleChange}
          />
          <Button
            color="primary"
            className="float-left"
            type="submit"
            style={{ width: "70px" }}
          >
            Join
          </Button>
        </FormGroup>
      </Form>
      {error && (
        <Alert className="mt-2 mb-0" color="danger">
          {error}
        </Alert>
      )}
    </Card>
  );
};

export default UserForm;
