import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import "../App.css";

const ChatVideo = ({ videoRef }) => {
  return (
    <Card
      style={{
        height: "335px",
        minWidth: "365px",
        textAlign: "center"
      }}
      className="mt-2"
    >
      <CardHeader className="font-weight-bold lead">Chat Users</CardHeader>
      <CardBody>
        <div className="users" ref={videoRef}></div>
      </CardBody>
    </Card>
  );
};

export default ChatVideo;
