import React, { useRef, useState, useEffect, Fragment } from "react";
import { Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import {
  getToken,
  joinRoom,
  displayPreview,
  leaveRoom,
  disconnectLocalParticipant,
  participantConnect,
  participantDisconnect,
  displayCurrentParticipants
} from "../utils";
import Header from "../components/Header";
import UserForm from "../components/UserForm";
import PreviewVideo from "../components/PreviewVideo";
import ChatVideo from "../components/ChatVideo";
import "../App.css";

const ChatRoom = () => {
  // STATE
  const { room } = useParams();
  const [user, setUser] = useState("");
  const [pin, setPin] = useState("");
  const [token, setToken] = useState("");
  const [joined, setJoined] = useState(false);
  const [error, setError] = useState("");
  // REFS
  const roomRef = useRef(null);
  const previewRef = useRef(null);
  const videoRef = useRef(null);
  // FUNCTIONS
  const handleSubmit = async evt => {
    evt.preventDefault();
    if (token) return;
    if (user !== "" && pin !== "" && token === "") {
      try {
        const token = await getToken(user, room, pin);
        setError("");
        setPin("");
        setToken(token);
      } catch (e) {
        setError(e.response.data.error);
      }
    } else {
      setError("Please fill out the form");
    }
  };
  const handleChange = ({ target: { name, value } }) => {
    setError("");
    if (name === "user") {
      setUser(value);
    } else if (name === "pin") {
      setPin(value);
    }
  };
  const onLeaveRoom = async () => {
    if (
      window.confirm("Are you sure you would like to leave this chat room?")
    ) {
      try {
        await leaveRoom(roomRef.current, room);
        setUser("");
        setToken("");
        setJoined(false);
      } catch (e) {
        // console.log("ERROR", e.response.data.error);
      }
    }
  };
  useEffect(() => {
    const programmableVideo = async () => {
      try {
        // JOIN ROOM WITH TOKEN
        roomRef.current = await joinRoom(token);
        setJoined(true);
        // DISPLAY YOUR PREVIEW VIDEO
        await displayPreview(previewRef.current);
        // HANDLE REMOTE PARTICIPANTS CONNECTING DURING CHAT
        roomRef.current.on(
          "participantConnected",
          participantConnect(videoRef.current)
        );
        // DISPLAY CURRENT MEMBERS IN CHAT
        displayCurrentParticipants(roomRef.current, videoRef.current);
        // LISTEN FOR WHEN YOU DISCONNECT FROM A CHAT
        roomRef.current.on(
          "disconnected",
          disconnectLocalParticipant(previewRef.current, videoRef.current)
        );
        // LISTEN FOR WHEN USERS DISCONNECT FROM CHAT
        roomRef.current.on(
          "participantDisconnected",
          participantDisconnect(roomRef.current)
        );
      } catch (error) {}
    };
    if (token) {
      programmableVideo();
    }

    return () => {
      if (roomRef.current) {
        roomRef.current.removeAllListeners("disconnected");
        roomRef.current.removeAllListeners("participantConnected");
        roomRef.current.removeAllListeners("participantDisconnected");
      }
    };
  }, [token]);

  return (
    <Fragment>
      <Header user={user} joined={joined} leaveRoom={onLeaveRoom} />
      <Container>
        <Row>
          <UserForm
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            user={user}
            pin={pin}
            error={error}
          />
        </Row>
        <Row>
          <PreviewVideo previewRef={previewRef} />
        </Row>
        <Row>
          <ChatVideo videoRef={videoRef} />
        </Row>
      </Container>
    </Fragment>
  );
};

export default ChatRoom;
