import axios from "axios";
import { connect, createLocalVideoTrack } from "twilio-video";

const getToken = async (user, room, pin) => {
  const {
    data: { token }
  } = await axios.get(
    `https://topaz-gopher-8158.twil.io/video-token?user=${user}&room=${room}&pin=${pin}`
  );
  return token;
};

const joinRoom = async (
  token,
  name = "twilio-video-api",
  audio = true,
  video = { width: 640 }
) => {
  const room = await connect(token, {
    name,
    audio,
    video
  });
  console.log(`Successfully joined a Room: ${room}`);
  return room;
};

const displayPreview = async ref => {
  const track = await createLocalVideoTrack();
  ref.appendChild(track.attach());
};

const leaveRoom = async (room, roomKey) => {
  room.disconnect();
  // const { data } = await axios.get(
  //   `https://topaz-gopher-8158.twil.io/leave-room?room=${roomKey}`
  // );
  // return data;
};

const disconnectLocalParticipant = (previewRef, videoRef) => room => {
  console.log("user disconnected");
  room.localParticipant.tracks.forEach(publication => {
    const attachedElements = publication.track.detach();
    attachedElements.forEach(element => element.remove());
  });
  previewRef.innerHTML = "";
  videoRef.innerHTML = "";
};

const participantConnect = ref => participant => {
  console.log("A REMOTE PARTICIPANT CONNECTED", participant.identity);
  participant.on("trackSubscribed", track => {
    console.log("HERE participant connect");
    let div = document.createElement("div");
    div.classList.add(participant.identity);
    div.style.float = "left";
    div.style.marginRight = "5px";
    ref.appendChild(div);
    div.appendChild(track.attach());
  });
};

const participantDisconnect = room => participant => {
  console.log(`Participant disconnected: ${participant.identity}`);
  console.log("PARTICIPANTS", room.participants);
  const disconnectedUser = document.querySelectorAll(
    `.${participant.identity}`
  );
  disconnectedUser.forEach(user => (user.innerHTML = ""));
};

const displayCurrentParticipants = (room, ref) => {
  const { participants } = room;
  participants.forEach(participant => {
    console.log(
      `Participant "${participant.identity}" is connected to the Room`
    );
    console.log("HERE display current participants");
    participant.on("trackSubscribed", track => {
      let div = document.createElement("div");
      div.classList.add(participant.identity);
      div.style.float = "left";
      div.style.marginRight = "5px";
      ref.appendChild(div);
      div.appendChild(track.attach());
    });
  });
};

export {
  getToken,
  joinRoom,
  displayPreview,
  leaveRoom,
  disconnectLocalParticipant,
  participantConnect,
  participantDisconnect,
  displayCurrentParticipants
};
