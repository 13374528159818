import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";

const NoMatch = () => {
  const location = useLocation();
  return (
    <Fragment>
      <div>
        <h3>
          No match for <code>{location.pathname}</code>
        </h3>
      </div>
    </Fragment>
  );
};

export default NoMatch;
