import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import "../App.css";

const PreviewVideo = ({ previewRef }) => {
  return (
    <Card
      style={{
        width: "365px",
        height: "335px",
        textAlign: "center"
      }}
      className="mt-2"
    >
      <CardHeader className="font-weight-bold lead">Preview Video</CardHeader>
      <CardBody>
        <div className="preview" ref={previewRef}></div>
      </CardBody>
    </Card>
  );
};

export default PreviewVideo;
